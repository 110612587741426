.navbar {
    display: flex;
    align-items: center;
    background-color: #27292e;
    color: whitesmoke;
}

.navbar .navbar-title {
    margin-right: auto;
    font-size: 150%;
    padding: 12px 16px;
}

.navbar .navbar-item {
    padding: 16px 16px;
    cursor: pointer;
    vertical-align: middle;
}