.randomizer {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
}

.randomizer .randomizer-content {
    display: flex;
    flex-direction: column;
    gap: 35px;
    position: relative;
}

@media screen and (max-width: 480px) {
    .randomizer .randomizer-content {
        padding-bottom: 50px;
    }
}

.randomizer .randomizer-header > div {
    display: flex;
    gap: 20px;
}

.randomizer .randomizer-navigation {
    display: flex;
    flex-direction: column;
    padding-top: 20px;
    gap: 15px;
}

.randomizer .randomizer-question-resources ul {
    padding-top: 3px;
    margin-left: 25px;
    line-height: 25px;
}
