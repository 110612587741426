.home {
    display: flex;
    flex-direction: column;
    justify-content: left;
    width: 100%;
}

.home .home-content {
    display: flex;
    flex-direction: column;
    gap: 16px;
    position: relative;
}

@media screen and (max-width: 480px) {
    .home .home-content {
        padding-bottom: 50px;
    }
}