.app {
  display: flex;
  flex-direction: column;
  background-color: #555a73;
  height: 100vh;
  width: 100vw;
  overflow: auto;
}

.app h1 {
  font-size: 25px;
}

.app .content {
  display: flex;
  height: 100vh;
  margin: 0 15vw;
  background-color: whitesmoke;
  border-left: 2px solid #44485c;
  border-right: 2px solid #44485c;
  padding: 32px;
  box-sizing: inherit;
}

@media screen and (max-width: 480px) {
  .app {
    overflow: auto;
  }

  .app .content {
    margin: 0;
    padding: 25px;
    overflow: auto;
  }
}

/*.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}*/
